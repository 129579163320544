import React from "react";
import {
  SEO,
  PageLayout,
  AnimatedSelector,
  BackgroundImages,
  MarkdownContent,
  OutboundLink,
  FishermanIcon,
  Section,
  Accordion,
  MultipleComponentIterator,
  MultipleComponentIteratorMap,
  InternalLink,
  BasicCarousel,
  componentIterator,
  Image,
  FadeReveal,
  FullImageCard,
  BlogPostLink,
  FormattedDate,
  PaginatedGrid,
  SocialMediaV2,
  multipleComponentIterator,
  ReviewV2,
  CustomerReviewModal,
} from "@bluefin/components";
import { Header, Button, Grid, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withArgs,
  withNamedArgs,
  getBusinessFiles,
} from "../utils/utils";
import {
  createServiceItemSlug,
  createServiceCategorySlug,
} from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      bookingUrl,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
      allFishermanBusinessFile,
      allFishermanBusinessBlogPost,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
          ogUrl={"https://www.designerae.com/"}
        />
        <PageLayout hero={false} subfooter={false} className={"home-page"}>
          <AnimatedSelector
            targetSelector={
              ".image.background::before, .image.background::after"
            }
            duration={1500}
            transitionType={"zoom"}
            transitionProps={{
              animating: { scale: 1 },
              preAnimating: { scale: 1.4 },
              serverSide: { scale: 1.4 },
            }}
            easing={"cubic-bezier(0,1,0.99,1)"}
            runPolicy={"RUN_ONCE_EVERY_VISIT"}
            animationKey={"team-hero-animation"}
          >
            <BackgroundImages
              images={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentName: "HeroElement",
              })}
              parentClassName={"hero-container"}
              imageClassName={"hero-image overlay"}
              carouselProps={{
                leftArrowIcon: "fancy-arrow-left",
                rightArrowIcon: "fancy-arrow-right",
              }}
              imageProps={{ loadStrategy: "eager" }}
            >
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "home_hero_header",
                  defaultValue: "Home",
                })}
              />
              <MarkdownContent
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "home_hero_description",
                  defaultValue:
                    "The ultimate destination for beauty and self-care",
                })}
              />
              <div className={"ctas-container"}>
                <Button
                  primary={true}
                  as={OutboundLink}
                  to={bookingUrl.url}
                  event={{
                    category: "Booking",
                    action: "Schedule Appointment Intent",
                  }}
                  icon={<FishermanIcon iconName={"fancy-arrow-right"} />}
                  labelPosition={"right"}
                  content={bookingUrl.title}
                />
              </div>
            </BackgroundImages>
          </AnimatedSelector>
          <Section className={"services-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "services_section_header",
                defaultValue: "Discover Services",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "services_section_description",
              })}
            />
            <Accordion exclusive={true} className={"services-accordion"}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessMenuCategory.nodes}
                limit={4}
                components={[
                  {
                    component: <React.Fragment />,
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: (
                          <Accordion.Title
                            activeIcon={"minus"}
                            inactiveIcon={"plus"}
                          />
                        ),
                        propMap: { index: "__itemIndex__" },
                        children: [
                          {
                            component: <Header as={"h2"} />,
                            children: [
                              {
                                component: <span />,
                                propMap: { children: "name" },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        component: <Accordion.Content />,
                        propMap: { index: "__itemIndex__" },
                        children: [
                          {
                            component: (
                              <Grid
                                className={"main content"}
                                doubling={true}
                                stackable={true}
                                columns={2}
                              />
                            ),
                            children: [
                              {
                                component: (
                                  <Grid.Column
                                    className={"services"}
                                    width={8}
                                  />
                                ),
                                children: [
                                  {
                                    component: <Grid />,
                                    children: [
                                      {
                                        component: (
                                          <MultipleComponentIteratorMap
                                            lookupField={"_id"}
                                            limit={4}
                                            iterator={
                                              allFishermanBusinessMenuItem.nodes
                                            }
                                            components={[
                                              {
                                                component: (
                                                  <Grid.Column width={16} />
                                                ),
                                                children: [
                                                  {
                                                    component: (
                                                      <InternalLink
                                                        event={{
                                                          category: "Services",
                                                          action:
                                                            "View Item Details",
                                                        }}
                                                      />
                                                    ),
                                                    propMap: {
                                                      "event.label": "name",
                                                      to: withArgs({
                                                        func: createServiceItemSlug,
                                                        args: [
                                                          allFishermanBusinessMenuCategory.nodes,
                                                        ],
                                                      }),
                                                    },
                                                    children: [
                                                      {
                                                        component: (
                                                          <Header as={"h3"} />
                                                        ),
                                                        propMap: {
                                                          children: "name",
                                                        },
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                            ]}
                                          />
                                        ),
                                        propMap: { targetValues: "items" },
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                component: (
                                  <Grid.Column
                                    className={"category-images"}
                                    width={8}
                                  />
                                ),
                                children: [
                                  {
                                    component: (
                                      <BasicCarousel
                                        size={1}
                                        displayIndicators={false}
                                        displayPlayPauseButton={false}
                                        autoScroll={false}
                                        leftArrowIcon={"fancy-arrow-left"}
                                        rightArrowIcon={"fancy-arrow-right"}
                                        animation={{
                                          name: "blur",
                                          duration: 200,
                                          transitionProps: {
                                            preAnimating: {
                                              opacity: 0.99,
                                              blur: "2px",
                                            },
                                            animating: {
                                              opacity: 0.99,
                                              blur: "2px",
                                            },
                                          },
                                        }}
                                      />
                                    ),
                                    propMap: {
                                      items: withNamedArgs({
                                        func: componentIterator,
                                        args: {
                                          iterator: withNamedArgs({
                                            func: getBusinessFiles,
                                            args: {
                                              businessFiles:
                                                allFishermanBusinessFile.nodes,
                                            },
                                          }),
                                          component: (
                                            <Image background={true} />
                                          ),
                                          propMap: { src: "__all__" },
                                        },
                                      }),
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            component: <div className={"button-container"} />,
                            children: [
                              {
                                component: (
                                  <Button
                                    basic={true}
                                    event={{
                                      category: "Services",
                                      action: "View Category Details",
                                    }}
                                    as={InternalLink}
                                    icon={
                                      <FishermanIcon
                                        iconName={"fancy-arrow-right"}
                                      />
                                    }
                                    content={"See All"}
                                    labelPosition={"right"}
                                  />
                                ),
                                propMap: {
                                  "event.label": "name",
                                  to: createServiceCategorySlug,
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </Accordion>
            <div className={"see-all-offering-button-container"}>
              <Button
                basic={true}
                icon={true}
                event={{ category: "Services", action: "View Services" }}
                as={InternalLink}
                to={"/services/"}
              >
                <span
                  children={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "see_all_services_button",
                    defaultValue: "See Full Service Offering",
                  })}
                />
                <FishermanIcon iconName={"fancy-arrow-right"} />
              </Button>
            </div>
          </Section>
          <Section className={"about-section"}>
            <Grid stackable={true} doubling={true} columns={2}>
              <Grid.Column width={6} className={"markdown-column"}>
                <div>
                  <FadeReveal duration={1250} triggerOnce={true}>
                    <Header
                      as={"h2"}
                      className={"section-header"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_section_header",
                        defaultValue: "Our Story",
                      })}
                    />
                    <MarkdownContent
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_section_description",
                      })}
                    />
                    <div className={"ctas-container"}>
                      <Button
                        secondary={true}
                        as={OutboundLink}
                        to={bookingUrl.url}
                        event={{
                          category: "Booking",
                          action: "Schedule Appointment Intent",
                        }}
                      >
                        {bookingUrl.title}
                      </Button>
                    </div>
                  </FadeReveal>
                </div>
              </Grid.Column>
              <Grid.Column width={10} className={"images-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <BasicCarousel
                    size={1}
                    displayIndicators={false}
                    displayPlayPauseButton={false}
                    autoScroll={false}
                    leftArrowIcon={"fancy-arrow-left"}
                    rightArrowIcon={"fancy-arrow-right"}
                    slide={true}
                    items={componentIterator({
                      iterator: getComponentFiles({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "ImageCollection",
                        componentIdentifier: "about_section_image_gallery",
                      }),
                      component: <Image background={true} />,
                      propMap: { src: "__all__" },
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </Section>
          <Section className={"blogs-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "blog_section_header",
                defaultValue: "Recent Articles",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "blog_section_description",
              })}
            />
            <Card.Group className={"blogs"} itemsPerRow={2} stackable={true}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessBlogPost.nodes}
                limit={2}
                components={[
                  {
                    component: <FullImageCard />,
                    propMap: { key: "_id", image: "gatsbyHeroImage" },
                    children: [
                      {
                        component: <BlogPostLink />,
                        propMap: { key: "_id", slug: "slug", title: "title" },
                        children: [
                          {
                            component: <Card.Header className={"title"} />,
                            propMap: { content: "title" },
                          },
                          {
                            component: <FormattedDate outputFormat={"PPP"} />,
                            propMap: { date: "publishedAt" },
                          },
                          {
                            component: (
                              <Card.Description className={"summary"} />
                            ),
                            propMap: { content: "summary" },
                          },
                          {
                            component: (
                              <FishermanIcon iconName={"fancy-arrow-right"} />
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </Card.Group>
            <div className={"see-all-blogs-button-container"}>
              <Button
                to={"/blog/"}
                basic={true}
                as={InternalLink}
                event={{ category: "Blog", action: "View All Blog Posts" }}
                icon={true}
              >
                <span
                  children={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "see_all_articles_button",
                    defaultValue: "Read All Articles",
                  })}
                />
                <FishermanIcon iconName={"fancy-arrow-right"} />
              </Button>
            </div>
          </Section>
          <Section className={"image-gallery-section"}>
            <PaginatedGrid
              className={"image-gallery tight-overlay"}
              rows={3}
              columns={4}
              tablet={{ columns: 3, rows: 3 }}
              mobile={{ columns: 2, rows: 2, stackable: false }}
              items={componentIterator({
                iterator: getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "ImageCollection",
                  componentIdentifier: "image_gallery",
                }),
                component: <Image background={true} />,
                propMap: { src: "__all__" },
              })}
            />
            <div className={"absolute-position-overlay"}>
              <Header
                as={"h2"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "image_section_header",
                  defaultValue: "Connect With Us",
                })}
              />
              <SocialMediaV2>
                <MultipleComponentIterator
                  iterator={fishermanBusiness.socialMedia}
                  components={[
                    {
                      component: <SocialMediaV2.Platform />,
                      propMap: { key: "_id", url: "link", platform: "type" },
                      children: [
                        {
                          component: <SocialMediaV2.Icon />,
                          propMap: { icon: "type" },
                        },
                      ],
                    },
                  ]}
                />
              </SocialMediaV2>
            </div>
          </Section>
          <Section className={"reviews-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "reviews_section_header",
                defaultValue: "What Our Clients Say",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "reviews_section_description",
              })}
            />
            <BasicCarousel
              className={"reviews-carousel faded-sides-carousel"}
              size={3}
              displayIndicators={false}
              stepSize={1}
              autoScroll={false}
              leftArrowIcon={"fancy-arrow-left"}
              rightArrowIcon={"fancy-arrow-right"}
              displayPlayPauseButton={false}
              slide={true}
              items={multipleComponentIterator({
                iterator: fishermanBusiness.reviews,
                components: [
                  {
                    component: <ReviewV2 />,
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: <ReviewV2.Image />,
                        propMap: { src: "image" },
                        children: [
                          {
                            component: (
                              <FishermanIcon iconName={"quote-left"} />
                            ),
                          },
                        ],
                      },
                      {
                        component: <ReviewV2.Text charLimit={360} />,
                        propMap: { text: "text" },
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Rating />,
                            propMap: { rating: "rating" },
                          },
                          {
                            component: <ReviewV2.RatingLabel />,
                            propMap: { rating: "rating" },
                          },
                        ],
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Author />,
                            propMap: { author: "author" },
                          },
                          {
                            component: <ReviewV2.Source />,
                            propMap: { source: "source", link: "link" },
                          },
                        ],
                      },
                    ],
                  },
                ],
              })}
            />
            <CustomerReviewModal
              businessId={fishermanBusiness._id}
              locations={allFishermanBusinessLocation.nodes}
              businessType={fishermanBusiness.type}
              triggerButtonProps={{
                basic: true,
                labelPosition: "right",
                icon: <FishermanIcon iconName={"fancy-arrow-right"} />,
              }}
              buttonText={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "submit_review_button",
                defaultValue: "Submit Review",
              })}
            />
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      socialMedia {
        _id
        link
        type
      }
      reviews {
        _id
        author
        text
        source
        rating
        link
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
    bookingUrl: fishermanBusinessWebsitePage(
      title: { regex: "/Book Now|Make An Appointment/" }
      pageType: { eq: "External" }
    ) {
      url
      title
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        _id
        description
        name
        items
        files
      }
    }
    allFishermanBusinessMenuItem(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
      filter: { visible: { eq: true } }
    ) {
      nodes {
        _id
        name
        description
        image
        visible
        available
        files
        variations {
          price
        }
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allFishermanBusinessBlogPost(filter: { destination: { eq: "BLOG" } }) {
      nodes {
        _id
        title
        slug
        summary
        heroImage
        gatsbyHeroImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        publishedAt
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
  }
`;
